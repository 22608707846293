import React from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import { Button } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import './carousel.css';

export const carouselFormatImage = graphql`
  fragment carouselFormatImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;

const MyCarousel = React.memo(() => {
  const data = useStaticQuery(graphql`
    query {
      education: file(relativePath: { eq: "home/home-education.png" }) {
        ...carouselFormatImage
      }
      restore: file(relativePath: { eq: "home/home-restore.jpg" }) {
        ...carouselFormatImage
      }
      iq: file(relativePath: { eq: "home/home-iq.jpg" }) {
        ...carouselFormatImage
      }
      cbsf: file(relativePath: { eq: "home/home-cbsf.png" }) {
        ...carouselFormatImage
      }
      therm: file(relativePath: { eq: "home/home-sto-therm.png" }) {
        ...carouselFormatImage
      }
    }
  `);

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 10000,
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        <div className="slide-wrapper">
          <div className="slide-image">
            <GatsbyImage
              image={data.education.childImageSharp.gatsbyImageData}
              alt="carousel-image"
              objectFit="fill"
            />
          </div>
          <div className="slide-info">
            <h2>Sto ofrece educación continua</h2>
            <Fade big>
              <p>
                Sto Colombia ofrece cursos selecionados como capacitaciones online, los cuales lo
                ayudaran a mantenerse actualizado sobre las mejores prácticas, productos y
                especificaciones comerciales.
              </p>
            </Fade>
            <Button className="carousel-btn">Conoce Mas</Button>
          </div>
        </div>
        <div>
          <div className="slide-wrapper">
            <GatsbyImage
              image={data.restore.childImageSharp.gatsbyImageData}
              alt="carousel-image"
            />
            <div className="slide-info">
              <h2>El arte es Restaurar (ReStore)</h2>
              <Fade>
                <p>
                  Estética, Reparación & Transformación Sto ofrece la solución más completa del
                  mercado para la restauración de edificios de fachada.
                </p>
              </Fade>
              <Button className="carousel-btn">Conoce Mas</Button>
            </div>
          </div>
        </div>
        <div>
          <div className="slide-wrapper">
            <GatsbyImage image={data.iq.childImageSharp.gatsbyImageData} alt="carousel-image" />
            <div className="slide-info">
              <h2>Tecnología Sto iQ</h2>
              <p>
                Son revestimientos arquitectónicos desarrollados con caracteristicas únicas y
                avanzadas, las cuales optimizan los rendimientos del edificio y generan acabados
                esteticamente versatiles
              </p>
              <Button className="carousel-btn">Conoce Mas</Button>
            </div>
          </div>
        </div>
        <div>
          <div className="slide-wrapper">
            <GatsbyImage image={data.cbsf.childImageSharp.gatsbyImageData} alt="carousel-image" />
            <div className="slide-info">
              <h2>La Creatividad comienza con acabados Sto</h2>
              <p>
                ¿Qué te inspira? ¿Cuál es tu desafio de diseño? ¿Que te hace diferente? Cualquiera
                que sea tu desafio Sto lo puedo lograr.
              </p>
              <Button className="carousel-btn">Conoce Mas</Button>
            </div>
          </div>
        </div>
        <div>
          <div className="slide-wrapper">
            <GatsbyImage image={data.therm.childImageSharp.gatsbyImageData} alt="carousel-image" />
            <div className="slide-info">
              <h2>Sto Therm ci / Sistema de Aislamiento Térmico</h2>
              <p>
                Soluciones inteligentes para exteriores que hacen que tu los edificios se ven
                brillantes y sostenibles, utiliza la experiencia Sto® y diseña muros exteriores
                integrado con soluciones avanzada capaces de satisfacer la energía y el diseño de
                hoy.
              </p>
              <Button className="carousel-btn">Conoce Mas</Button>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
});

export default MyCarousel;
