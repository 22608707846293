import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import fadeup from '../images/home/home-fadeup.png';

// Components
import Layout from '../components/layout';
import Seo from '../components/seo';
import Carousel from '../components/carousel';

// styled-component
const MobileHero = styled.div`
  display: none;

  @media only screen and (max-width: 425px) {
    display: block;
    width: 100%;
    height: 300px;
  }
`;

const HomeTitle = styled.div`
  background: #fafafa;
  padding-top: 1.45rem;
  padding-bottom: 1rem;
`;

const H1 = styled.h1`
  text-align: center;
  font-weight: 400;
  color: #000;

  @media only screen and (max-width: 425px) {
    font-size: 1.2rem;
  }
`;

const HomeText = styled.div`
  text-align: center;
  padding: 2rem 10rem;

  @media only screen and (max-width: 768px) {
    padding: 2rem 2rem;
  }

  @media only screen and (max-width: 425px) {
    padding: 2rem 2rem;
  }
`;

const HomeTextSpan = styled.span`
  font-size: 1.71em;
  color: #000;

  @media only screen and (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const HomeTextP = styled.p`
  font-size: 1.3em;
  color: #666666;
  padding: 2rem 0;

  @media only screen and (max-width: 768px) {
    font-size: 1em;
  }
`;

const HomeCardsWrapper = styled.div`
  background-color: #fff;
  padding: 30px;
  width: 100%;

  @media only screen and (max-width: 425px) {
    padding: 16px;
  }
`;

const HomeCardWrapper = styled.div`
  border: 1px solid #f0f0f0;
  text-align: center;
  height: 322px;
  width: 100%;
  position: relative;

  &:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const HomeCard = styled.div`
  position: absolute;
  width: 100%;
  height: 322px;
  top: 0;
  padding-top: 250px;
  background-image: url(${fadeup});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &:hover {
    text-decoration: underline;
  }
`;

const HomeCardH1 = styled.h1`
  color: #fff;
  font-size: 24px;
  font-weight: 200;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const MobileHeroText = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: rgb(255, 215, 0);
`;

export const homeFormatImage = graphql`
  fragment homeFormatImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;

const IndexPage = React.memo(() => {
  const data = useStaticQuery(graphql`
    query {
      architect: file(relativePath: { eq: "home/home-architects.png" }) {
        ...homeFormatImage
      }
      contractor: file(relativePath: { eq: "home/home-contractors.jpg" }) {
        ...homeFormatImage
      }
      builder: file(relativePath: { eq: "home/home-builders.jpg" }) {
        ...homeFormatImage
      }
      cbsf: file(relativePath: { eq: "home/home-cbsf.png" }) {
        ...homeFormatImage
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Home" />
      <Carousel />
      <MobileHero>
        <GatsbyImage
          image={data.cbsf.childImageSharp.gatsbyImageData}
          style={{ backgroudSize: 'contain', height: '200px' }}
          alt="logo-cbsf"
        />
        <MobileHeroText>
          <span>Construir a Conciencia</span>
        </MobileHeroText>
      </MobileHero>
      <HomeTitle>
        <H1>
          Te ayudamos a realizar tu <b>visión</b>.
        </H1>
      </HomeTitle>
      <HomeCardsWrapper>
        <Row gutter={20}>
          <Col xs={24} md={8} lg={8} xl={8}>
            <Link to="/">
              <HomeCardWrapper>
                <GatsbyImage
                  image={data.architect.childImageSharp.gatsbyImageData}
                  style={{ backgroudSize: 'contain', height: '322px' }}
                  alt="logo-architect"
                />
                <HomeCard>
                  <HomeCardH1>Soluciones para Arquitectos </HomeCardH1>
                </HomeCard>
              </HomeCardWrapper>
            </Link>
          </Col>
          <Col xs={24} md={8} lg={8} xl={8}>
            <Link to="/">
              <HomeCardWrapper>
                <GatsbyImage
                  image={data.contractor.childImageSharp.gatsbyImageData}
                  style={{ backgroudSize: 'cover', height: '322px' }}
                  alt="logo-contractor"
                />
                <HomeCard>
                  <HomeCardH1>Soluciones para Contratistas </HomeCardH1>
                </HomeCard>
              </HomeCardWrapper>
            </Link>
          </Col>
          <Col xs={24} md={8} lg={8} xl={8}>
            <Link to="/">
              <HomeCardWrapper>
                <GatsbyImage
                  image={data.builder.childImageSharp.gatsbyImageData}
                  style={{ backgroudSize: 'contain', height: '322px' }}
                  alt="logo-builder"
                />
                <HomeCard>
                  <HomeCardH1>Soluciones para Constructoras </HomeCardH1>
                </HomeCard>
              </HomeCardWrapper>
            </Link>
          </Col>
        </Row>
      </HomeCardsWrapper>
      <HomeText>
        <strong>
          <HomeTextSpan>
            Sto es su socio para soluciones de construcción estéticas, sostenibles y de alto
            rendimiento.
          </HomeTextSpan>
        </strong>
        <HomeTextP>
          Sto es el líder mundial en el diseño y la producción de materiales de construcción
          innovadores adaptados a las necesidades humanas. Estamos comprometidos con el desarrollo
          de soluciones de construcción de próxima generación que proporcionan una estética sin fin
          en armonía con nuestros sistemas de pared totalmente diseñados. Estos incluyen sistemas de
          revestimiento de aislamiento continuo, sistemas de protección contra la lluvia, soluciones
          de prefabricación, barreras de aire y humedad, revestimientos y soluciones de
          restauración. Sto ha sido la elección de los principales arquitectos, ingenieros y
          contratistas durante más de 40 años y nuestros empleados son defensores del dominio
          técnico en la búsqueda de un entorno construido sostenible.
        </HomeTextP>
      </HomeText>
    </Layout>
  );
});

export default IndexPage;
